
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import { campHandleBrPhoneNumber, campHandleCPF, campHandleEmailVerification, campHandleEveryoneIsTrue } from '@/composables/DataValidation';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useAuthStore } from '@/store/AuthStore';
  
  
  interface IUserData {
    cpf: string,
    name: string,
    email: string,
    tel: string,
    type: string,
    latestAccessCode: string,
    moderator: boolean,
    consultant: boolean,
    birth_date: string,
  }
  
  interface IUserResponse {
    data: {
      error: boolean,
      message: string,
      data: IUserData
      errorCode: string
    },
    status: number
  }
  
  export default defineComponent({
    name: "CompanyPut",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn,
    },
    setup() {
      const route = useRoute();
      const authStore = useAuthStore()
      const initData: IUserData = {
        cpf: "",
        name: "",
        email: "",
        tel: "",
        type: "",
        latestAccessCode: "",
        moderator: false,
        consultant: true,
        birth_date: "",
      }
      const queryObject = ref<{
        isLoading: boolean,
        noError: boolean,
        data:  null | IUserData
      }>({
        isLoading: false,
        noError: true,
        data: { ...initData }
      })
      const competitorCheck = ref(false)
  
      const loaderStore = useLoaderStore();
  
      onMounted(async () => {
        loaderStore.open()
        queryObject.value.isLoading = loaderStore.status
        try {
          const result: IUserResponse = await axios.get(`/api/getCompetitorView/${id}`)
          const { data } = result
          if(!data.error) {
            queryObject.value.noError = true
            queryObject.value.data = data.data
            const cpf = campHandleCPF(queryObject.value.data.cpf, "formatted")
            queryObject.value.data.cpf = typeof cpf === "string" ? cpf : queryObject.value.data.cpf
            // const tel = campHandleBrPhoneNumber(queryObject.value.data.tel, "formatted")
            // queryObject.value.data.tel = typeof tel === "string" ? tel : queryObject.value.data.tel
            competitorCheck.value = !queryObject.value.data.consultant
          }
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          queryObject.value.isLoading = loaderStore.status
        }
      })
  
      const { id } = route.params;
      /** Warning of unfilled fields */
      const isRequiredField = ref(false)
      const isRequiredNameComplete = ref(false)
      const isInvalidFieldWarning = ref(false)
      const isInvalidField = ref<{ 
        cpf: boolean,
        mail: boolean,
        phoneNumber: boolean,
       }>({
        cpf: false,
        mail: false,
        phoneNumber: false,
      })
  
      /** Validate CPF */
      watch(() => queryObject.value.data?.cpf, () => {
        if(queryObject.value.data?.cpf) {
          isInvalidField.value.cpf = !campHandleCPF(queryObject.value.data.cpf)
        }
      })
  
      /** Validate email */
      // watch(() => queryObject.value.data?.email, () => {
      //   if(queryObject.value.data?.email) {
      //     isInvalidField.value.mail = !campHandleEmailVerification(queryObject.value.data.email)
      //   } else isInvalidField.value.mail = false
      // })
  
      /** Validate phone number */
      watch(() => queryObject.value.data?.tel, () => {
        if(queryObject.value.data?.tel) {
          isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(queryObject.value.data.tel)
        } else isInvalidField.value.phoneNumber = false
      })
  
      /** Reset warning */
      watch(() => queryObject.value.data, () => {
        if(isRequiredField.value)
          isRequiredField.value = false
        if(isInvalidFieldWarning.value)
          isInvalidFieldWarning.value = false
      },
      { deep: true })
  
      const { showTimeAlert } = useAlert()
  
      async function onSubmitForm() {
        
        const { data } = queryObject.value
        if(!data) {
          return router.push("/404")
        }
        if(
          !data.cpf.length ||
          !data.name.length ||
          !data.tel.length ||
          // !data.email.length ||
          !data.birth_date?.length
        ) {
          return isRequiredField.value = true
        }
        if(!data.name.includes(' ')) return isRequiredNameComplete.value = true
        if(campHandleEveryoneIsTrue(isInvalidField.value)) {
          isInvalidFieldWarning.value = true
          return isRequiredField.value = true
        }
        try {
          loaderStore.open()
          queryObject.value.isLoading = loaderStore.status
          const { data } = queryObject.value
          if(data) {
            // const {tel, cpf, card_number, card_pin, ...res} = data
            const tel = campHandleBrPhoneNumber(data.tel, "unformatted")
            const cpf = campHandleCPF(data.cpf, "unformatted")
            if(typeof tel === "boolean" || typeof cpf === "boolean") {
              loaderStore.close()
              return showTimeAlert("Algo deu errado!", "error")
            }       
            const putData: IUserData = {
              ...data,
              tel,
              cpf,
              consultant: !competitorCheck.value
            }
            if(putData.moderator)
              putData.consultant = !putData.moderator

            await axios.put(`/api/putCompetitor`, putData)
            showTimeAlert("Atualizado com sucesso!")
            return router.push("/consultores")
          }
        } catch (error) {
          loaderStore.close()
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          queryObject.value.isLoading = loaderStore.status
        }
      }
  
      return {
        queryObject,
        isRequiredField,
        isInvalidFieldWarning,
        isInvalidField,
        onSubmitForm,
        authStore,
        isRequiredNameComplete,
        competitorCheck,
      }
    }
  })
  